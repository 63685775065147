:root {
  padding: 0;
  margin: 0;
  
}

/* .bg-custom {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../public/assets/background.png");
} */



.main-container{
  background-color: white;
  position: fixed;
  bottom: 40px;
  right: 10px;
  height: 85vh;
  width: 30%;
  font-family: 'Josefin Sans', sans-serif;
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0,0,0,.25);
 
}




.headre{
    background-color: white;
    height: 70px;
    text-align: center;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: 1px solid whitesmoke;
    
}
.close_btn{
  margin-right: 15px;
  cursor: pointer;
}
.header_icon_container{
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 10px;
}
.icon{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.header_text_con{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.headerText{
  font-weight: 600;
  font-size: 20px;
}
.subText{
  font-size: 14px;
  color: gray;
}

.chat-input {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  padding: 0px 12px;
  border-radius: 10px;
  margin-top: 10px;
}

.chat-input input {
  flex-grow: 1;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Josefin Sans', sans-serif;
  color: black;
  border: none;
  outline: none;
  background: transparent;
}
.chat-input input::placeholder{
  color: gray;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 16px;
}
.chat-input button {
  margin-left: 2px;
  border-radius: 10px;
  font-size: 16px;
  border: none;
  outline: none;
  background: transparent;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 75vh;
  width: 100%;
  font-family: 'Josefin Sans', sans-serif;
  
}

.chats {
  padding: 5px 15px;
  padding-bottom: 10px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: whitesmoke;
}
.avtar {
  height: 40px;
  margin: 5px 10px 0px 0px;
  border-radius: 50%;
  border: 1px solid rgb(211, 203, 203);
  padding: 2px;
  background-color: rgb(228, 221, 181);
}

.chat {
  display: flex;
  align-items: center;
  border-radius: 15px;
  padding: 10px;
  margin-top: 10px;
  width: 90%;
}
.chat p {
  flex-grow: 1;
}

.send .avtar {
  order: 1;
}
.recieve .avtar {
  order: 0;
}

.send {
  align-self: flex-end;
  background-color: rgb(245, 242, 214);
  border-bottom-right-radius: 0px;
  margin-top: 20px;
}
.recieve {
  background-color: rgb(220, 240, 243);
  border-bottom-left-radius: 0px;
}

.message-text {
  margin: 1px;
}


/* //Form */
.form-container{
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 75vh;
  padding: 0px 20px;
  font-family: 'popins', sans-serif;
}
.form-container form{
  width: 100%;
  height: 70vh;
}
.form_text_container{
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid whitesmoke;
  width: 100%;
}
.form_icon{
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-label{
  margin-top: 20px;
  margin-bottom: 5px;
  color: gray;
  font-weight: 500;
}
.form-input-container{
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgb(208, 207, 207);
  outline: none;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Josefin Sans', sans-serif;
  color: black;
}

.form-input-container:focus{
  border-color: rgb(0,102,255);
}

.form-input-container::placeholder{
    color: lightgray;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
}
.submit_btn{
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: rgb(0,102,255);
  color: white;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
.submit_btn:hover{
  background-color: rgb(6, 90, 217);
}
.form-side{
  background-color: aquamarine;
  width: 40%;
}
.error{
  font-size: 12px;
  color: red;
  margin: 0;
  padding: 0;
  margin-top: 2px;
}
.chat_bot_btn{
  position: fixed;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  transition: all 0.2s;
}
.chat_bot_btn img{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: all 0.8s;
}
.popdown{
  bottom: -100px;
  transition: all 0.6s;
}
/* Translate */
.translate{
  margin-top: 5px;
  text-align: start;
  font-size: 13px;
  color: gray;
}
.translate span{
  cursor: pointer;
}
.translate span:hover{
  color: blueviolet;
  text-decoration: underline;
}
.translated_text{
  font-size: 14px;
}
.late_transition{
  top: -100px;
  animation: drop 0.5s ease forwards;
}
@keyframes drop {
  0%{opacity: 0}
  70%{ transform: translateY(0px);}
  100%{ transform: translateY(10px); opacity: 1;}
}
/* Media  */
@media (max-width: 1100px) {
  .main-container{
    width: 50%;
  }
}
@media (max-width: 950px) {
  .main-container{
    width: 65%;
  }
}
@media (max-width: 650px) {
  .main-container{
    width: 75%;
  }
}
@media (max-width: 520px) {
  .main-container{
    width: 80%;
    right: 25px;
  }
}
@media (max-width: 440px) {
  .main-container{
    width: 95%;
    right: 8px;
  }
  .chat-input input{
    flex-grow: 0;
  }
}